.image-container {
  margin-top: 10rem;
  display: flex;
  justify-content: center;
  width: 100% !important;
}

.pano-container {
  width: 80vw !important;
  max-width: 1200px !important;
}

