.DescriptionPage {
  background: linear-gradient(
    360deg,
    #aca777 -3.47%,
    #a8534e 63.79%,
    #250bc6 118.52%
  );
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}
/* Your global styles */
.swiper-pagination {
  margin-bottom: 6rem;
  padding: 10px;
  z-index: 1;
}

/* Custom styles for pagination bullets */
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  opacity: 0.7;
  padding: 10px;
  border: 2px solid #fff !important;
  transition: background-color 0.3s ease;
}

/* Active bullet style */
.swiper-pagination-bullet-active {
  background-color: #fff !important;
  border-color: #fff;
  opacity: 1;
}

/* YourCustomSwiperStyles.css */

.swiper-container {
  position: relative;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  bottom: 10rem;
  left: 50%;
  transform: translateX(-50%);
  margin: 4px; /* Adjust the margin as needed */
  background-color: transparent; /* Ensure the background is transparent */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color: #a8534e;
  display: flex;
  font-size: 16px; /* Adjust the font size of the navigation icons */
}

.UpperDiv {
  width: 100%;
}

.hero-column {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center; /* You can adjust the position as needed */
}

/* .left-column {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 45px;
  width: 150px;
  height: 100%;
  left: 150px;
  align-content: space-between;
  flex-wrap: wrap;
} */

.image {
  width: 100px;
  height: 100px;
}

/* .hero {
  width: 100%;
  margin: 0px 450px;
} */

/* .right-column {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  right: 10px;
} */

/* .description-map-container-top {
  position: absolute;
  align-items: flex-start;
  right: 0;
  width: 35%;
  height: 400px;
} */

.nav {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  right: 20px;
}

.nav a {
  text-decoration: none;
  margin: 5px 5px;
}

.location-timepoint {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 350px;
  font-size: 14px;
  left: 30px;
}

.location-timepoint li {
  margin: 5px 0;
}

.main-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 70%;
  gap: 10px;
  align-items: center;
  gap: 20px;
}

.description {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  z-index: 2;
  margin-top: -60px;
}

.desctiption-container {
  padding: 1.5rem;
  display: flex;
  width: 80%;
  gap: 30px;
  background-color: rgba(
    255,
    255,
    255,
    0.3
  ); /* Add a semi-transparent background */
  backdrop-filter: blur(2px); /* Adjust the blur amount as needed */
  border-radius: 10px;
}

.description-map-container {
  position: relative;
  top: 10%;
  width: 30%;
  height: 300px;
}
/* .description-map {
  display: flex;
  gap: 10px;
  overflow-y: hidden;
} */

.flex {
  display: flex;
}

.w-full {
  width: 100%;
}
.description-content {
  width: 70%;
}

.description-title {
  font-weight: 900;
  font-size: 36px;
  color: #fff;
  padding: 5px 0;
}
.description p {
  font-size: 20px;
  padding: 10px 0;
  overflow-wrap: break-word;
}

/* .read-more {
  color: blue;
  cursor: pointer;
} */

.youtube-video-container {
  border: 8px solid #fff;
  width: 1080px;
  height: 615px;
}

.justify-center {
  justify-content: center;
  margin-top: 10%;
  position: relative;
}

.Place-title {
  position: relative;
  margin-top: 10%;
  left: 5%;
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}

.pano-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  z-index: 0;
}

.custom-swiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-swiper {
  /* Add your custom styles for the first Swiper here */
  /* Example: */
  background-color: #f0f0f0;
  /* ... */
}

.custom-swiper .main-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
}
.des-navigation-buttons {
  position: absolute;
  bottom: -65px;
  right: 650px;
  display: flex;
  gap: 0;
}
.des-nav-container {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
}
/* .custom-swiper .swiper-button-prev,
.custom-swiper .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 30px);
    right: auto;
}

.custom-swiper .swiper-button-prev,
.custom-swiper .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 65%);
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; 
} */

.near-frame-container {
  position: relative;
  top: 30%;
  left: -20px;
  display: flex;
  flex-wrap: wrap;
  gap: 200px;
  margin-top: 80px;
  justify-content: center;
  align-items: center;
}
.near-by-place-container {
  cursor: pointer;
}

.near-by-place {
  display: flex;
  flex-direction: column;
}
