.bg-gradient {
  background: linear-gradient(
    358.74deg,
    #aca777 -7.08%,
    #a8534e 51.24%,
    #250bc6 107.39%
  );
}
.form-container {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Inter" !important;
  background-color: white;
  width: 650px;
  border: 0px solid transparent;
  border-radius: 20px;
  /* padding-top: 10px; */
  margin-bottom: 3rem;
}

.form-container-upper-div {
  border-radius: 20px 20px 0px 0px;
  padding: 20px 0px 20px 0px;
  background-color: #efefef;
  display: flex;
  width: 100%;
  align-items: center;
}

.form-container-bottom-div {
  border-radius: 20px 20px 0px 0px;
  padding: 20px 0px 20px 0px;
  display: flex;
  width: 100%;
  align-items: center;
}

.form-container-upper-paragraph {
  /* padding: 44px, 89px, 44px, 89px; */
  width: 100%;
  background-color: #efefef;
  justify-content: center;
  display: flex;
}

.form-container-bottom-paragraph {
  width: 100%;
  justify-content: center;
  display: flex;
}

.grid-container {
  display: grid;
  grid-template-rows: auto; /* Define four rows */
  grid-template-columns: 1fr 1fr; /* Define two columns for the second row */

  /* Optional: Set grid gap for better visualization */
  gap: 10px;
  padding-top: 30px;
  padding-bottom: 10px;
}

.error {
  color: red;
  padding: 0;
  margin: 0;
}

.input-fields {
  padding: 1rem 0;
}

.grid-container > :first-child {
  grid-column: 1 / span 2; /* Span two columns */
}

.grid-item:nth-child(4),
.grid-item:nth-child(5) {
  grid-column: 1 / span 2; /* Span two columns */
}

.form-input {
  margin: 5px 0;
  padding: 5px 0;
  border: none;
  border-bottom: 2px solid #000; /* Add a bottom border with your desired color (here, it's black) */
  outline: none; /* Remove the default focus outline (optional) */
}

.grid-container > :last-child {
  color: white;
  border-radius: 20px;
  border: 0px solid;
  padding: 10px 50px;
  font-weight: 500;
  background-color: #4d21a1;
  grid-column: span 2; /* Span two columns */
  justify-self: center; /* Center the item horizontally within the grid cell */
  /* width: 50%; */
}

.contact-us-container {
  position: relative;
  z-index: 1;
  color: white;
  height: 50vh;
  justify-content: center;
  align-items: center;

  background: linear-gradient(
    358.74deg,
    #aca777 -7.08%,
    #a8534e 51.24%,
    #250bc6 107.39%
  );
  display: flex;
  line-height: 0%;
}
.contact-us-contents {
  width: 80%;
  height: 20%;
  display: flex;
  flex-direction: column;
  gap: 70%;
  line-height: 0px;
}

.contact-us-content {
  display: flex;
  flex-direction: column;
}

.contact-us-content :nth-child(3),
.contact-us-content :nth-child(4) {
  text-decoration: underline;
}

.contact-us-content :nth-child(3) {
  font-weight: 700;
}

.contact-us-content p {
  font-size: 16px;
}
.contact-us-header {
  font-size: 34px;
  font-family: "Inter" !important;
  font-weight: 600;
}

.contact-us-icons {
  position: relative;
  display: flex;
  gap: 25px;
  margin-top: -50px;
}

.contact-us-icon {
  height: 25px;
}

.next-step-container {
  color: #4d21a1;
  font-family: "Inter" !important;
}

.next-step-container h5 {
  font-size: 35px;
  line-height: 0;
  font-weight: 300 !important;
}

.next-step-container h3 {
  font-size: 50px;
  line-height: 0;
  font-weight: 900 !important;
}

.contact-us-second-container {
  height: 400px;
  display: flex;
  background-color: #efefef;

  justify-content: space-around;
}
.contact-footer{
  z-index: 1;
}
