.scene {
  width: 100%;
  height: 130vh;
  position: relative;
  overflow: hidden;
  text-align: center; /* Center content */
}
body {
  margin: 0px;
}
/* Images (example sizes and positions, adjust as needed) */
.sun {
  position: absolute;
  width: 10%;
  height: auto;
  top: 10%;
  left: 60%;
}

.temple {
  position: absolute;
  width: 30%;
  height: auto;
  top: 10%;
  left: 70%;
  z-index: 4;
}

.forest {
  position: absolute;
  width: 80%;
  height: auto;
  top: 13%;
  left: 0%;
  z-index: 1;
}

.hill {
  position: absolute;
  width: 80%;
  height: auto;
  top: 40%;
  left: 0%;
  z-index: 2;
}

.land {
  position: absolute;
  width: 100%;
  height: auto;
  top: 57%;
  left: 0;
  z-index: 3;
}

.star {
  position: absolute;
  width: 100%;
  height: auto;
  top: 10%;
  left: 5%;
}

.wave {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  left: 0;
  top: 66%;
  z-index: 6;
}

.explore-nepal {
  text-align: left;
  position: absolute;
  width: 70%;
  height: auto;
  top: 9%;
  left: 10%;
  z-index: 5;
}

.ex-nepal {
  font-size: 6vw;
  left: 5%;
  color: #f5e290;
}

.embark {
  position: absolute;
  font-size: 2vw;
  left: 0%;
  top: 65%;
  font-weight: lighter;
  color: #f5e290;
}

.get-started {
  position: absolute;
  left: 0%;
  top: 90%;
  color: #fff;
}
.scroll-button {
  display: block;
  cursor: pointer;
  position: absolute;
  left: 540px;
  /* transform: translateX(-50%); */
  font-size: 1rem;
  top: 350px;
  font-weight: lighter;
  color: #f5e290;
  height: 60px;
  background: none;
  border: none;
}
