/* Map container */
.map-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

/* Map itself */
.map {
  width: 100%; /* Adjust the width as needed */
  height: 100%; /* Adjust the height as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
